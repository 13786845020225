import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/fr"
import "../style/contact.scss"
import { Link } from "gatsby"

const remark = require('remark')
const remarkHtml = require('remark-html')

export default ({ location, data }) => {
  const contact = remark()
    .use(remarkHtml)
    .processSync(data.contact.frontmatter.body_fr).toString();
  const infos = remark()
    .use(remarkHtml)
    .processSync(data.informations.frontmatter.body_fr).toString();
  return <Layout location={location}>
    <div className="contact">
      <div dangerouslySetInnerHTML={{ __html: contact }}></div>
      <Link to={`/fr/mention`}>Mentions légales</Link>
    </div>
    <div className="row informations">
      <div className="offset-lg-3 col-lg-9 offset-md-0 col-md-12" dangerouslySetInnerHTML={{ __html: infos }}></div>
    </div>
  </Layout>
}


export const pages = graphql`
  query {
    contact: markdownRemark(fileAbsolutePath: {regex: "/(pages/contact.md)/"}) {
      frontmatter {
        body_fr
      }
    }
    informations: markdownRemark(fileAbsolutePath: {regex: "/(pages/informations.md)/"}) {
      frontmatter {
        body_fr
      }
    }
  }
`